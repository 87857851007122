import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useForm, get } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { postShiftsAction, updateUserAbsenceDaysAction } from '../../actions/shift';
import { ErrorField, StyledSelect, Textarea } from '../../lib/HooksFormFields';
import CustomInputNumber from '../../lib/HooksFormFields/InputNumber';
import { IOption } from '../../lib/HooksFormFields/StyledSelect/style';
import { IShift } from '../../types/shift';
import {
  ILine, IPole, IPool, IStructure,
} from '../../types/structure';
import { IUser } from '../../types/user';
import Icon from '../Icon/Icon';
import styles from './ShiftModal.module.scss';
import InputDate from '../../lib/HooksFormFields/InputDate';

const LineToggle = (
  {
    line,
    isActive,
    handleLine,
    isCurrent,
  }
  :
  {
    line: ILine,
    isActive: boolean,
    isCurrent: boolean,
    handleLine: () => void,
  },
) => (
  <button
    type="button"
    className={`${styles.line} ${isActive ? styles.active : ''} ${isCurrent ? styles.current : ''}`}
    onClick={() => handleLine()}
  >
    <div className={styles.check}>
      <FaCheck />
    </div>
    <p>{line?.name}</p>
  </button>
);

const ShiftModal = ({
  user,
  line,
  pool,
  shifts,
  lines,
  close,
}: {
  user?: IUser,
  line?: ILine,
  lines?: ILine[],
  pool?: IPool,
  shifts?: IShift[]
  close?: () => void,
}) => {
  const {
    watch, reset, setValue, control, setError, handleSubmit, formState: { errors },
  } = useForm<any>();
  const dispatch = useDispatch();
  const [isOtherAbscence, setIsOthorAbscence] = useState(false);
  const [isMoreDays, setIsMoreDays] = useState(false);
  const {
    list, shift, shiftsErrors, shiftsAlerts,
  } = useSelector((store: any) => store.shiftsReducer);
  const { filters } = useSelector((store: any) => store.usersReducer);
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const form = watch();
  const poles = structuresList.find((s: IStructure) => s._id === filters.structure)
    ?.poles?.filter((p: IPole) => {
      if (!p.isActive) {
        return null;
      }
      if (p?.activities?.length === 0) {
        return null;
      }
      if (user && user?.poles.find((u) => u === p._id)) {
        return p;
      }
      if (line) {
        return p;
      }
      return null;
    });

  const activities = poles
    ?.find((p: IPole) => p._id === form?.pole)
    ?.activities?.map((a: IOption) => ({ value: a.label, label: a.label }));
  const hoursOptions: IOption[] = list?.shifts
    ?.map((s: IOption) => ({ label: s.label, value: s.label, start: s.start })) || [];

  const selectedStart = hoursOptions.find((h: IOption) => h.label === form.start);
  const dateFormated = filters?.startDate && new Intl.DateTimeFormat('fr', { dateStyle: 'full' }).format(new Date(filters?.startDate));
  // const dailyHours = user?.contract?.hours && user.contract.hours / 5;

  const countHours = form?.duration?.hours > 0
    ? (form.duration.hours * 60) / 5 : 0;
  const countHMinutes = form?.duration?.minutes || 0;
  const shiftCount = countHours + (countHMinutes / 5);

  const updatedSlices = list.shifts
    .filter((s: IOption) => s.start
      && selectedStart?.start
      && s.start >= selectedStart.start)
    .map((s: IOption) => s.label).splice(0, shiftCount);

  const currentSlices = shifts?.filter((s: IShift) => s.isActive)?.map((s) => s.slices).flat();
  const isCurrentSlice = !!currentSlices?.find(
    (c: string) => updatedSlices.find((u: string) => u === c),
  );

  const isUpdate = shift?._id && shift.slices.length === updatedSlices.length;
  // Des créneaux sont déjà attribués sur cette tranche horaire

  async function onSubmit() {
    if (!form.activity) {
      return setError('activity', {
        type: 'custom',
        message: 'l\'activite est requise',
      });
    }
    if (isMoreDays) {
      const startDate = new Date(`${form.startDate}T00:00:00.000Z`).toISOString();
      const endDate = new Date(`${form.endDate}T00:00:00.000Z`).toISOString();
      const date = new Date(`${format(filters.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
      const data = {
        startDate,
        endDate,
        user: user?._id,
        activity: form.activity,
        structure: filters.structure,
        date,
        text: form.text,
      };

      await updateUserAbsenceDaysAction(dispatch, data);
      if (close) {
        close();
      }
      return null;
    }
    const slicesEndIndex = hoursOptions.findIndex(
      (h) => h.label === updatedSlices[updatedSlices.length - 1],
    );
    const nextStart = hoursOptions.find(
      (h, i) => i === slicesEndIndex + 1,
    );
    if (updatedSlices.length > 0) {
      const startDate = new Date(`${format(filters.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
      const shiftRes = await postShiftsAction(dispatch, {
        user: user?._id,
        line: line?._id,
        structure: filters.structure,
        date: startDate,
        pole: form.pole,
        activity: form.activity,
        slices: updatedSlices,
        lines: form?.lines,
        isValid: form.isValid,
        duration: form.duration,
      });
      if (nextStart?.label) {
        setValue('start', nextStart?.label);
      }
      if (shiftRes?.errors?.length > 0) {
        setValue('start', form.start);
      }

      if (shift?._id
        && close
        && shiftRes?.errors?.length === 0
        && shiftRes?.alerts?.length === 0) {
        close();
      }
    }

    return null;
  }

  function handleLine(id: string | undefined) {
    if (!id) {
      return;
    }
    if (id === line?._id) {
      return;
    }
    let currentLines = form.lines || [];
    const foundLine = currentLines.find((c: string) => c === id);
    if (foundLine) {
      currentLines = currentLines.filter((c: string) => c !== id);
    } else {
      currentLines.push(id);
    }
    setValue('lines', currentLines);
  }

  async function deleteShift() {
    const startDate = new Date(`${format(filters.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
    await postShiftsAction(dispatch, {
      user: user?._id,
      line: line?._id,
      structure: filters.structure,
      date: startDate,
      pole: shift.pole,
      slices: updatedSlices,
      activity: 'rest',
      duration: shift.duration,
    });
    reset({
      startDate,
      duration: {
        hours: 1,
        minutes: 0,
      },
    });
    if (close) {
      return close();
    }
    return null;
  }

  useEffect(() => {
    if (filters) {
      let pole = user?.poles?.length ? user?.poles[0] : null;
      if (shift) {
        let { activity } = shift;
        pole = shift.pole || pole;
        const abscence = list?.activitiesOptions.filter((a: any) => a.value !== 'break').find((a: any) => a.value === activity);
        if (activity !== 'rest' && activity !== 'paid vacation' && abscence) {
          setIsOthorAbscence(true);
          pole = null;
        }
        if (activity === 'rest') {
          activity = null;
        }
        if (activity === 'break') {
          setIsOthorAbscence(false);
          pole = null;
        }
        if (activity === 'paid vacation') {
          pole = null;
        }
        reset({
          startDate: filters.startDate,
          pole,
          start: shift.slices[0],
          activity,
          isValid: shift.isValid,
          duration: shift.duration,
        });
      }
    }
  }, [filters, shift, user]);

  useEffect(() => {
    if (form.pole) {
      setIsMoreDays(false);
    }
    if (form.pole && !form.activity && activities?.length > 0) {
      setValue('activity', activities[0].value);
    }
  }, [activities, form.pole]);

  useEffect(() => {
    if (isMoreDays) {
      reset({
        ...form,
        text: '',
        startDate: format(filters.startDate, 'yyyy-MM-dd'),
        endDate: format(filters.startDate, 'yyyy-MM-dd'),
      });
    }
  }, [isMoreDays]);

  return (
    <div className={styles.modale}>
      {user
        && <div className={styles.title}>
          <h2>{shift.activity !== 'rest' ? 'Modifier le créneau de' : 'Ajouter un créneau à'} {' '}</h2>
          {user?.picture?.path
            && <div className={styles.avatar}>
              <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`} alt={user.picture.alt} />
            </div>
          }
          <h2>{user.profile.firstName} {user.profile.lastName}</h2>
        </div>
      }
      {pool
        && <div className={styles.title}>
          <h2>{shift.activity !== 'rest' ? 'Modifier le créneau' : 'Ajouter un créneau'} {' '}</h2>
          <h2>{line?.name} du {pool.name}</h2>
        </div>
      }
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.field}>
            <label>Catégories</label>
            <div className={styles.poles}>
              {poles?.map((d: IPole, i: number) => <button
                key={`pole-${i}`}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setValue('pole', d._id);
                  setValue('activity', null);
                  setIsOthorAbscence(false);
                }}
                className={form?.pole === d._id ? styles.active : ''}
              >
                <div
                  className={styles.icon}
                  style={{
                    backgroundColor: form?.pole === d._id ? d?.color : 'transparent',
                    border: `1px solid ${d?.color}`,
                  }}
                >
                  <Icon
                    selectedIcon={d.icon}
                    color={form?.pole === d._id ? '#FFFFFF' : '#8D97A0'}
                  />
                </div>
                <div className={styles.tooltip}>
                  <div className={styles.infos}>
                    <div
                      className={styles.puce}
                      style={{
                        backgroundColor: d?.color,
                      }}
                    />
                    <p>{d?.name}</p>
                  </div>
                </div>
              </button>)}
              {list?.activitiesOptions.filter((a: any) => !a.isOther && a.value === 'break')?.map((a: any) => <button
                key={a.value}
                onClick={() => {
                  if (a.value !== 'other') {
                    setValue('activity', a.value);
                    setIsOthorAbscence(false);
                  } else {
                    setIsOthorAbscence(true);
                  }
                  setValue('pole', null);
                }}
                className={form?.activity === a.value || (a.value === 'other' && isOtherAbscence) ? styles.active : ''}
              >
                <div
                  className={styles.icon}
                  style={{
                    backgroundColor: form?.activity === a.value || (a.value === 'other' && isOtherAbscence) ? '#8D97A0' : 'transparent',
                    border: '1px solid #8D97A0',
                  }}
                >
                  <Icon
                    selectedIcon={a.value}
                    color={form?.activity === a.value || (a.value === 'other' && isOtherAbscence) ? '#FFFFFF' : '#8D97A0'}
                  />
                </div>
                <div className={styles.tooltip}>
                  <div className={styles.infos}>
                    <div
                      className={styles.puce}
                      style={{
                        backgroundColor: a?.color,
                      }}
                    />
                    <p>{a?.label}</p>
                  </div>
                </div>
              </button>)}
            </div>
          </div>
        </div>
        {user
          && <div className={styles.col}>
            <div className={styles.field}>
              <label>Repos / Absences</label>
              <div className={styles.poles}>
                {list?.activitiesOptions.filter((a: any) => !a.isOther && a.value !== 'break')?.map((a: any) => <button
                  key={a.value}
                  onClick={() => {
                    console.log(a.value);
                    if (a.value !== 'other') {
                      setValue('activity', a.value);
                      setIsOthorAbscence(false);
                    } else {
                      setIsOthorAbscence(true);
                      setValue('activity', null);
                    }
                    setValue('pole', null);
                  }}
                  className={form?.activity === a.value || (a.value === 'other' && isOtherAbscence) ? styles.active : ''}
                >
                  <div
                    className={styles.icon}
                    style={{
                      backgroundColor: form?.activity === a.value || (a.value === 'other' && isOtherAbscence) ? '#8D97A0' : 'transparent',
                      border: '1px solid #8D97A0',
                    }}
                  >
                    <Icon
                      selectedIcon={a.value}
                      color={form?.activity === a.value || (a.value === 'other' && isOtherAbscence) ? '#FFFFFF' : '#8D97A0'}
                    />
                  </div>
                  <div className={styles.tooltip}>
                    <div className={styles.infos}>
                      <div
                        className={styles.puce}
                        style={{
                          backgroundColor: a?.color,
                        }}
                      />
                      <p>{a?.label}</p>
                    </div>
                  </div>
                </button>)}
              </div>
            </div>
          </div>
        }
      </div>
      <div>
        {(form?.activity === 'paid vacation' || isOtherAbscence)
          && <div className={styles.daysToggle}>
            <button
              type="button"
              className={!isMoreDays ? styles.active : ''}
              onClick={() => setIsMoreDays(false)}
            >
              Poser un jour
            </button>
            <button
              type="button"
              className={isMoreDays ? styles.active : ''}
              onClick={() => setIsMoreDays(true)}
            >
              Poser plusieurs jours
            </button>
          </div>
        }
        {activities && !isOtherAbscence
          && <div className={styles.activity}>
            <label>Activité</label>
            <div className={styles.select}>
              <StyledSelect
                name="activity"
                control={control}
                options={activities.sort(
                  (a: IOption, b: IOption) => a.label && b.label && a.label.localeCompare(b.label),
                )}
              />
            </div>
          </div>
        }
        {isOtherAbscence
          && <div className={styles.activity}>
            <label>Activité</label>
            <div className={styles.select}>
              <StyledSelect
                name="activity"
                control={control}
                options={list?.activitiesOptions.filter((a: any) => a.isOther).sort(
                  (a: IOption, b: IOption) => a.label && b.label && a.label.localeCompare(b.label),
                )}
              />
            </div>
          </div>
        }
        {typeof get(errors, 'activity')?.message === 'string' && (
          <ErrorField message={get(errors, 'activity')?.message || ''} />
        )}
        {form?.activity && !isMoreDays
          && <div className={styles.field}>
            <div className={styles.hours}>
              <div className={styles.select}>
                <p className={styles.label}>Le {dateFormated}, à</p>
                <div className={styles.col}>
                  <StyledSelect
                    name="start"
                    control={control}
                    rules={{
                      required: 'l\'heure de début est requise',
                    }}
                    options={hoursOptions}
                  />
                </div>
              </div>
              {selectedStart?.start
                && <div className={styles.duration}>
                  <p className={styles.label}>Durée</p>
                  <CustomInputNumber
                    name="duration.hours"
                    control={control}
                    rules={{}}
                    step={'1'}
                    className={'duration'}
                  />
                  H
                  <CustomInputNumber
                    name="duration.minutes"
                    control={control}
                    rules={{}}
                    step={'5'}
                    max={60}
                    className={'duration'}
                  />
                </div>
              }
            </div>
            {typeof get(errors, 'start')?.message === 'string' && (
              <ErrorField message={get(errors, 'start')?.message || ''} />
            )}
          </div>
        }
        {form?.activity && isMoreDays
          && <div className={styles.moreDays}>
            <div className={styles.dates}>
              <div className={styles.field}>
                <InputDate
                  label='Date de début'
                  name='startDate'
                  control={control}
                  rules={{
                    required: 'la date de début est requise',
                  }}
                />
                {typeof get(errors, 'startDate')?.message === 'string' && (
                  <ErrorField message={get(errors, 'startDate')?.message || ''} />
                )}
              </div>
              <div className={styles.field}>
                <InputDate
                  label='Date de fin'
                  name='endDate'
                  control={control}
                  rules={{
                    required: 'la date de fin est requise',
                  }}
                />
                {typeof get(errors, 'endDate')?.message === 'string' && (
                  <ErrorField message={get(errors, 'endDate')?.message || ''} />
                )}
              </div>
            </div>
            <div className={styles.text}>
                <Textarea
                  name='text'
                  control={control}
                  rules={{ }}
                  placeholder='Commentaire'
                />
            </div>
          </div>
        }
        {lines && form?.activity
          && <div className={styles.lines}>
            <label>Activité</label>
            {lines?.filter((l) => l?._id).map((l) => <LineToggle
              key={l._id}
              line={l}
              isCurrent={l._id === line?._id}
              isActive={l._id === line?._id || form?.lines?.find((c: string) => c === l._id)}
              handleLine={() => handleLine(l._id)}
            />)}
          </div>
        }
        {shiftsErrors?.length > 0
          && <div className={styles.errors}>
            {shiftsErrors.map((error: string) => (
              <div key={error} className={styles.error}>
                <p dangerouslySetInnerHTML={{ __html: error }} />
              </div>
            ))}
          </div>
        }
        <div className={styles.alerts}>
          {shiftsAlerts?.length > 0 && shiftsAlerts.map((alert: string) => (
            <div key={alert} className={styles.alert}>
              <p dangerouslySetInnerHTML={{ __html: alert }} />
            </div>
          ))}
          {isCurrentSlice && !isUpdate && user
            && <div className={styles.alert}>
              <p>Des créneaux sont déjà attribués sur cette tranche horaire</p>
            </div>
          }
        </div>
      </div>
      <div className={styles.actions}>
        <div>
          {shift.activity
            && <button
              type="button"
              onClick={() => deleteShift()}
              className={styles.delete}
            >
              Supprimer le créneau
            </button>
          }
        </div>
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          className={styles.add}
        >
          Appliquer
        </button>
      </div>
    </div>
  );
};

export default ShiftModal;
