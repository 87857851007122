import React, { useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import styles from './ImportUserModal.module.scss';
import { IUser } from '../../types/user';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import InputDate from '../../lib/HooksFormFields/InputDate';
import { importUserDayShiftsAction } from '../../actions/shift';

const ImportUserModal = ({ user, close }: { user: IUser, close: () => void }) => {
  const dispatch = useDispatch();
  const { usersList, filters } = useSelector((store: any) => store.usersReducer);

  const formatedDate = filters?.startDate && new Intl.DateTimeFormat('fr', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(filters?.startDate));

  const { control, handleSubmit, formState: { errors } } = useForm<FieldValues>({
    defaultValues: {
      user: user?._id,
      structure: filters?.structure,
      date: filters?.startDate,
      dateSource: format(new Date(filters?.startDate), 'yyyy-MM-dd'),
    },
  });

  async function onSubmit(values: FieldValues) {
    const data = {
      ...values,
    };
    if (data?.dateSource) {
      data.dateSource = new Date(`${data.dateSource}T00:00:00.000Z`).toISOString();
    }
    console.log(data);
    await importUserDayShiftsAction(dispatch, data);
    await close();
  }

  const usersOptions = useMemo(() => usersList
    .filter((u: IUser) => u.isActive && u.role === 'Member' && u._id !== user?._id)
    .map((u: IUser) => ({
      value: u._id,
      label: `${u.profile.firstName} ${u.profile.lastName}`,
    })), [usersList]);

  return (<div className={styles.container}>
    <h4>
      Importer un jour à {user.profile?.firstName} {user.profile?.lastName}<br />le {formatedDate}
    </h4>

    <div className={styles.field}>
      <StyledSelect
        label="Choisir la personne"
        name='userSource'
        control={control}
        options={usersOptions}
        rules={{
          required: 'La personne est requise',
        }}
      />
      {typeof errors.userSource?.message === 'string' && <ErrorField message={errors?.userSource?.message} />}
    </div>

    <div className={styles.field}>
      <InputDate
        label="Choisir la date"
        name='dateSource'
        rules={{
          required: 'La date est requise',
        }}
        control={control}
      />
      {typeof errors.dateSource?.message === 'string' && <ErrorField message={errors?.dateSource?.message} />}
    </div>

    <div className={styles.actions}>
      <button
        onClick={handleSubmit(onSubmit)}
      >
        Valider
      </button>

    </div>

  </div>
  );
};

export default ImportUserModal;
