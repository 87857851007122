/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FaPen } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ImArrowLeft2, ImArrowRight2 } from 'react-icons/im';
import Header from '../../components/Header';
import UserForm from '../../components/User/UserForm';
import UserInfos from '../../components/User/UserInfos';

import styles from './User.module.scss';
import { createUserAction, getUserAction, updateUserAction } from '../../actions/users';
import { getAllStructuresAction } from '../../actions/structures';
import { GET_CONTRACT } from '../../actions/types';
import { getUserContractsAction } from '../../actions/contract';
import { IUser } from '../../types/user';

const User = ({ isCreate = false }: { isCreate?: boolean }) => {
  const [searchParams] = useSearchParams();
  const defaultIsEdit = searchParams.get('edit') === 'true' || isCreate;
  const [isEdit, setIsEdit] = useState(defaultIsEdit);
  const id = useParams()?.id;
  const navigate = useNavigate();

  const {
    user, error, filters, usersList,
  } = useSelector((store: any) => store.usersReducer);

  const dispatch = useDispatch();
  const methods = useForm();
  const onSubmit = async (data: FieldValues) => {
    const formData = { ...data };
    methods.clearErrors('email');
    methods.clearErrors('code');
    if (data?.picture?._id) {
      formData.picture = data?.picture._id;
    }
    if (formData?.role === 'Admin' || formData.role === 'Owner') {
      formData.structure = null;
    }
    if (user?._id) {
      if (formData?.contract && !formData?.contract?.isAnnualized) {
        formData.contract.annualizedHours = null;
      }
      const res = await updateUserAction(dispatch, formData, user._id);
      if (res.data?.user) {
        setIsEdit(false);
      }
      return null;
    }
    const res = await createUserAction(dispatch, formData);
    if (res?.data) {
      return navigate('/admin/user');
    }
    return null;
  };

  async function handleUsersNav(value: number) {
    const userIndex = usersList.findIndex((u: IUser) => u._id === id);
    const nextIndex = value > 0 ? userIndex + value : userIndex - Math.abs(value);
    let found = usersList.find((u: IUser, i: number) => i === nextIndex);
    if (!found) {
      found = usersList[0];
    }
    await navigate(`/admin/user/${found?._id}`);
  }

  useEffect(() => {
    getAllStructuresAction(dispatch);
  }, []);

  useEffect(() => {
    const userData = user ? {
      ...user,
      color: user.color || '#222429',
    } : {
      color: '#222429',
      isActive: true,
      contract: {
        isAnnualized: false,
      },
    };
    if (user?._id) {
      if (userData?.structure?._id) {
        userData.structure = userData.structure._id;
      }
      if (userData?.contract?.startDate) {
        userData.contract.startDate = userData?.contract.startDate.split('T')[0];
      }
      if (userData?.contract?.endDate) {
        userData.contract.endDate = userData?.contract.endDate.split('T')[0];
      }
    } else if (filters.structure) {
      userData.structure = filters.structure;
    }
    methods.reset(userData);
    dispatch({
      type: GET_CONTRACT,
      payload: null,
    });
  }, [user]);

  useEffect(() => {
    if (id) {
      getUserAction(dispatch, id);
      getUserContractsAction(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (error?.data?.error === 'That email address is already in use.' || error?.data?.error === 'La valeur du ou des champs suivant est déjà utilisé : email.') {
      methods.setError(
        'email',
        {
          type: 'custom',
          message: 'l\'email est déja utilisé',
        },
      );
    }
    if (error?.data?.error === 'That code is already in use.') {
      methods.setError(
        'code',
        {
          type: 'custom',
          message: 'le matricule est déja utilisé',
        },
      );
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <Header name='Mon profil' />
      <div className={styles.content}>
        {!isEdit && usersList.length > 0
          && <div className={styles.nav}>
            <button
              type="button"
              onClick={() => handleUsersNav(-1)}
            >
              <ImArrowLeft2 />
            </button>
            <button
              onClick={() => handleUsersNav(1)}
            >
              <ImArrowRight2 />
            </button>
          </div>
        }

        {!isEdit
          ? <button className={styles.edit} onClick={() => setIsEdit(true)}>
            <div className={styles.icon}>
              <FaPen />
            </div>
            <span>MODIFIER LES INFOS</span>
          </button>
          : <button className={styles.edit} onClick={methods.handleSubmit(onSubmit)}>
            <div className={styles.icon}>
              <BsCheck size={28} />
            </div>
            <span>Valider les modifications</span>
          </button>
        }
        <FormProvider {...methods} >
          {isEdit
            ? <UserForm
              onSubmit={(obj: any) => onSubmit(obj)}
            />
            : <UserInfos
              onSubmit={(obj: any) => onSubmit(obj)}
            />}
        </FormProvider>
      </div>
    </div>
  );
};

export default User;
