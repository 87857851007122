/* eslint-disable prefer-destructuring */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ImArrowLeft2, ImArrowRight2 } from 'react-icons/im';
import Header from '../../components/Header';

import styles from './UserForecast.module.scss';
import { getUserAction } from '../../actions/users';
import { getAllStructuresAction } from '../../actions/structures';
import { IUser } from '../../types/user';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import ForecastForm from '../../components/ForecastForm';

const UserForecast = () => {
  const id = useParams()?.id;
  const navigate = useNavigate();

  const {
    usersList, user,
  } = useSelector((store: any) => store.usersReducer);

  const userAuth = useSelector((store: any) => store.authReducer)?.user;

  const filteredUsers = useMemo(() => usersList.filter((u: IUser) => {
    if (userAuth?.role === 'Manager') {
      if (u.role === 'Member' && u.isActive && u.poles.find((p: string) => userAuth?.poles.find((pu: string) => p === pu))) {
        return u;
      }
      return null;
    }
    return u;
  }), [userAuth, usersList]);

  const dispatch = useDispatch();

  async function handleUsersNav(value: number) {
    const userIndex = filteredUsers.findIndex((u: IUser) => u._id === id);
    const nextIndex = value > 0 ? userIndex + value : userIndex - Math.abs(value);
    let found = filteredUsers.find((u: IUser, i: number) => i === nextIndex);
    if (!found) {
      found = filteredUsers[0];
    }
    await navigate(`/admin/user/forecast/${found?._id}`);
  }

  useEffect(() => {
    getAllStructuresAction(dispatch);
  }, []);

  useEffect(() => {
    if (id) {
      getUserAction(dispatch, id);
    }
  }, [id]);

  return (
    <div className={styles.container}>
      <Header name='Mon profil' />
      <div className={styles.content}>
      <div className={styles.bg} />
        {filteredUsers.length > 0
          && <div className={styles.nav}>
            <button
              type="button"
              onClick={() => handleUsersNav(-1)}
            >
              <ImArrowLeft2 />
            </button>
            <button
              onClick={() => handleUsersNav(1)}
            >
              <ImArrowRight2 />
            </button>
          </div>
        }
        <div className={styles.avatar}>
          <InputMedia
            name="picture"
            media={user?.picture}
            disabled={true}
          />
          <div className={styles.name}>
            <h1>
              {user?.profile?.firstName}
              {' '}
              {user?.profile?.lastName}
            </h1>
            {user?.structure?.name && userAuth.role !== 'Manager'
            && <p>{user?.structure?.name}</p>}
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.links}>
            <Link to={`/admin/user/${id}?edit=true`} className={styles.link}>
              Informations
            </Link>
            <Link to={`/admin/user/contract/${id}`} className={styles.link}>
              Contrats
            </Link>
            <Link to={`/admin/user/forecast/${id}`} className={styles.link}>
              Prévisionnel
            </Link>
          </div>
          <div className={styles.forecast}>
            <ForecastForm />
          </div>
        </div>
      </div>

    </div>
  );
};

export default UserForecast;
