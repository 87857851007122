import React, { useEffect, useRef } from 'react';
import { useFormContext, get } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { IoTrash } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { deleteContractAction } from '../../actions/contract';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import Checkbox from '../../lib/HooksFormFields/Checkbox';
import InputDate from '../../lib/HooksFormFields/InputDate';
import InputNumber from '../../lib/HooksFormFields/InputNumber';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import { Period } from '../../types/contract';
// import { IStructure } from '../../types/structure';
import styles from './ContractForm.module.scss';

export const contractOptions = ['CDI', 'CDD'].map((d) => ({ label: d, value: d }));

const ContractForm = () => {
  const {
    control, formState: { errors }, watch, setValue, setError, clearErrors,
  } = useFormContext<any>();
  // const { structuresList } = useSelector((store: any) => store.structureReducer);
  const dispatch = useDispatch();
  // const structuresOptions = structuresList
  //   ?.map((s :IStructure) => ({ value: s._id, label: s.name }));
  const { contract } = useSelector((store: any) => store.contractsReducer);

  const form = watch();
  const endModalRef = useRef<IModalHandle | any>(null);
  const isCompleted = form?.periods?.reduce((a: number, b: Period) => a + b.hours, 0) || 0;

  async function handleAddPeriod() {
    const last = form?.periods[form.periods.length - 1];
    const periods = [...form.periods];
    if (last?.endDate) {
      const startYear = new Date(last?.endDate).getFullYear();
      periods.push({
        startDate: `${startYear}-06-01`,
        endDate: `${startYear + 1}-05-31`,
        hours: last.hours,
      });
      setValue('periods', periods);
    }
  }

  async function handleCloseContract() {
    if (isCompleted > 0 && form.isAnnualized && form?.closeDate && !contract.endDate) {
      const periods = [...form.periods];
      const periodIndex = periods.findIndex(
        (p) => new Date(form.closeDate) >= new Date(p.startDate)
          && new Date(form.closeDate) <= new Date(p.endDate),
      );
      clearErrors('periods');
      if (periodIndex === periods.length - 1) {
        setValue(`periods[${periodIndex}].endDate`, form.closeDate);
        setValue('endDate', form.closeDate);
        endModalRef.current.close();
      } else {
        setError('periods', { type: 'custom', message: 'la dernière ne correspond à cette date de fin de contrat.' });
      }
    } else {
      setValue('endDate', form.closeDate);
      endModalRef.current.close();
    }
  }

  function generatePeriodes(start: Date, end: Date) {
    const periods = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    const firstEnd = startDate.getMonth() < 5 ? `${startDate.getFullYear()}-05-31` : `${startDate.getFullYear() + 1}-05-31`;
    if (endDate.getTime() < new Date(firstEnd).getTime()) {
      return [
        {
          startDate: form.startDate,
          endDate: form.endDate,
          hours: 0,
        },
      ];
    }

    periods.push({
      startDate: form.startDate,
      endDate: firstEnd,
      hours: 0,
    });

    if (endDate.getTime() > new Date(firstEnd).getTime()) {
      let startYear = new Date(firstEnd).getFullYear();
      while (new Date(`${startYear + 1}-05-31`).getTime() < endDate.getTime()) {
        periods.push({
          startDate: `${startYear}-06-01`,
          endDate: `${startYear + 1}-05-31`,
          hours: 0,
        });
        startYear += 1;
      }
      periods.push({
        startDate: `${startYear}-06-01`,
        endDate: form.endDate,
        hours: 0,
      });
    }

    return periods;
  }

  useEffect(() => {
    if (form?.isAnnualized && form.startDate) {
      const startDate = new Date(form.startDate);
      const endDate = form?.endDate ? new Date(form.endDate) : null;
      // `${startYear}-05-31`;
      let periods: any = [];
      if (endDate && endDate.getTime() > startDate.getTime()) {
        const currentStart = new Date(startDate);
        const currentEnd = new Date(endDate);
        periods = generatePeriodes(currentStart, currentEnd);
      }
      if (!endDate) {
        const end = startDate.getMonth() < 5 ? `${startDate.getFullYear()}-05-31` : `${startDate.getFullYear() + 1}-05-31`;
        periods.push({
          startDate: form.startDate,
          endDate: end,
          hours: 0,
        });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const [i] of periods.entries()) {
        const prev = form.periods[i];
        if (prev?.hours) {
          periods[i].hours = prev?.hours;
        }
      }
      setValue('periods', periods);
    }
    if (form?.periods && !form.isAnnualized) {
      setValue('periods', []);
    }
  }, [form?.isAnnualized, form?.startDate, form?.endDate]);

  useEffect(() => {
    if (isCompleted === 0 && form.isAnnualized) {
      setError('periods', {
        type: 'custom',
        message: 'Veuillez saisir les heures travaillées.',
      });
    } else {
      clearErrors('periods');
    }
  }, [form?.isAnnualized, isCompleted, form?.periods]);

  useEffect(() => {
    clearErrors('endDate');
  }, [form.type]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.col}>
            {/* <div className={styles['container-field']}>
            <StyledSelect
              name="structure"
              control={control}
              label="Site"
              options={structuresOptions}
              rules={{
                required: 'Veuillez renseigner le site',
              }}
            />
            {typeof get(errors, 'structure')?.message === 'string' && (
              <ErrorField message={get(errors, 'structure')?.message || ''}/>
            )}
          </div> */}
            <div className={styles['container-field']}>
              <StyledSelect
                name="type"
                control={control}
                label="Type"
                options={contractOptions}
                rules={{
                  required: 'Veuillez renseigner un type de contrat',
                }}
              />
              {typeof get(errors, 'type')?.message === 'string' && (
                <ErrorField message={get(errors, 'type')?.message || ''} />
              )}
            </div>
            <div className={styles['container-field']}>
              <InputDate
                name="startDate"
                control={control}
                label="Date de début"
                rules={{
                  required: 'Veuillez renseigner la date de début',
                }}
              />
              {typeof get(errors, 'startDate')?.message === 'string' && (
                <ErrorField message={get(errors, 'startDate')?.message || ''} />
              )}
            </div>
            {(!contract?._id || form?.endDate)
              && <div className={styles['container-field']}>
                <InputDate
                  name="endDate"
                  control={control}
                  label="Date de fin"
                  rules={form?.type === 'CDD' ? {
                    required: 'Veuillez renseigner la date de fin',
                  } : {}}
                />
                {typeof get(errors, 'endDate')?.message === 'string' && (
                  <ErrorField message={get(errors, 'endDate')?.message || ''} />
                )}
              </div>
            }
            {(contract?.startDate && !contract?.endDate && !form.endDate)
              && <button
                type="button"
                className={styles.end}
                onClick={() => endModalRef?.current.open()}
              >
                <span>Cloturer le contrat</span>
              </button>

            }
            <div className={styles['container-field']}>
              <InputNumber
                name="weekHours"
                control={control}
                label="Horaire /semaine"
                unit='Heures'
                rules={{}}
              />
            </div>
            <div className={styles['container-field']}>
              <Checkbox
                name="isAnnualized"
                control={control}
                label="Contrat annualisé"
                rules={{}}
              />
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.periods}>
              {form?.periods?.map((p: Period, i: number) => <div
                key={p?._id || `${p.startDate.toString()}-${i}`}
                className={styles.period}
              >
                <div className={styles.item}>
                  <InputNumber
                    name={`periods[${i}].hours`}
                    control={control}
                    rules={{}}
                    className='period'
                  />
                  <p>Heures</p>
                </div>
                <div className={styles.item}>
                  <p>Début <span>{p?.startDate.toString()}</span></p>
                </div>
                <div className={styles.item}>
                  <p>Fin <span>{p?.endDate.toString()}</span></p>
                </div>
                <div className={styles['delete-item']}>
                  {!form.endDate
                    && p.hours === 0
                    && <button
                      onClick={() => setValue('periods', form?.periods.filter((pe: Period, pi: number) => pi !== i))}
                    >
                      <IoTrash />
                    </button>
                  }
                </div>
              </div>)}
            </div>
            <div className={styles.error}>
              {typeof get(errors, 'periods')?.message === 'string' && (
                <ErrorField message={get(errors, 'periods')?.message || ''} />
              )}
            </div>
            {!contract.endDate && form?.periods?.length > 0
              && <button
                type="button"
                className={styles.add}
                onClick={() => handleAddPeriod()}
              >
                <div className={styles.icon}>
                  <FaPlus size={16} />
                </div>
                <span>Ajouter une année</span>
              </button>
            }
          </div>
        </div>
        {form?._id
          && <button
            type='button'
            className={styles.delete}
            onClick={() => deleteContractAction(dispatch, form?._id)}
          >
            Supprimer le contrat
          </button>
        }
      </div>
      <ModalStandalone
        ref={endModalRef}
        closeCallBack={() => {
          clearErrors();
        }}
      >
        <div className={styles.modal}>
          <h3>Cloturer le contrat</h3>
          <div className={styles['container-field']}>
            <InputDate
              name="closeDate"
              control={control}
              label="Date de fin"
              rules={{ required: 'La date de fin est requise' }}
            />
            {typeof get(errors, 'periods')?.message === 'string' && (
              <ErrorField message={get(errors, 'periods')?.message || ''} />
            )}
          </div>
          {form.closeDate && new Date(form.closeDate) > new Date(form.startDate)
            && <button
              type='button'
              className={styles.submit}
              onClick={() => handleCloseContract()}
            >
              Cloturer le contrat
            </button>
          }
        </div>
      </ModalStandalone>
    </>
  );
};

export default ContractForm;
