import { isSameDay } from 'date-fns';
import React, { useRef, useState } from 'react';
import { AiTwotoneWarning } from 'react-icons/ai';
import { BsCheck, BsPersonFillDown } from 'react-icons/bs';
import { IoMdAdd } from 'react-icons/io';
import { IoTrash } from 'react-icons/io5';
import { RiFileFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteDayShiftAction, saveCurrentDayShiftAction } from '../../actions/shift';
import { GET_NOTE, SET_USERS_FILTERS, SET_WEEK_VIEW } from '../../actions/types';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import { IContract } from '../../types/contract';
import { INote } from '../../types/note';
import { INotif } from '../../types/notif';
import { DayType, IShift } from '../../types/shift';
import { ILine, IPool } from '../../types/structure';
import { IUser } from '../../types/user';
import ShiftModal from '../ShiftModal/ShiftModal';
import TimeLine from '../TimeLine/TimeLine';
import styles from './PlanningRow.module.scss';
import ImportUserModal from '../ImportUserModal';
import ConfirmModal from '../ConfirmModal';

export default function PlanningRow({
  user,
  line,
  shifts = [],
  isMobile,
  week,
  pool = null,
  name = null,
  lines = null,
}: {
  user?: IUser | null,
  line?: ILine | null,
  lines?: ILine[] | null,
  pool?: IPool | null,
  isMobile: boolean,
  shifts: IShift[],
  week: DayType[]
  name?: string | null,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalRef = useRef<IModalHandle | any>(null);
  const confirmModalRef = useRef<IModalHandle | any>(null);
  const modalImportRef = useRef<IModalHandle | any>(null);
  const authReducer = useSelector((d: any) => d.authReducer);
  const { contracts } = useSelector((store: any) => store.contractsReducer);
  const userAuth = authReducer?.user;
  const { filters } = useSelector((store: any) => store.usersReducer);
  const { notifs } = useSelector((d: any) => d.notifsReducer);
  const { notes } = useSelector((d: any) => d.notesReducer);

  const note = notes?.find((n: INote) => n.user?._id === user?._id);

  const currentContract = user && contracts.filter(
    (c: IContract) => c.user === user._id,
  ).find(
    (c: IContract) => {
      const endDate = c?.endDate && new Date(c?.endDate);
      if (c?.endDate && endDate?.getTime() < new Date(filters.startDate)?.getTime()) {
        return null;
      }
      return c;
    },
  );

  const absencesOptions = currentContract?.isAnnualized ? ['paid vacation'] : [];
  const [forcastIsOpen, setForcastIsOpen] = useState(false);

  const forcastShifts = shifts.filter((s: IShift) => s.user && !s.isActive);
  const activeShifts = shifts?.filter((s: IShift) => s.user
    && !absencesOptions?.find((a: string) => a === s.activity));

  const validShifts = shifts.filter((s: IShift) => s.user
    && !absencesOptions?.find((a: string) => a === s.activity) && s.isValid);
  const isValid = activeShifts?.length > 0 && activeShifts?.length === validShifts?.length;
  const activeShiftCount = activeShifts.filter((s: IShift) => s.isActive).map(
    (s) => s.slices,
  ).flat();
  const hours: number = activeShiftCount?.length ? (activeShiftCount.length * 5) / 60 : 0;
  const minutes = (hours - Math.floor(hours)) * 60;

  const dateUpdate = notifs.find(
    (n: INotif) => n.author._id === user?._id
      && n.date === filters.startDate
      && n.type === 'dateUpdate',
  );

  function handleSelectDate(obj: any) {
    dispatch({
      type: SET_WEEK_VIEW,
      payload: false,
    });
    dispatch({
      type: SET_USERS_FILTERS,
      payload: {
        ...filters,
        ...obj,
      },
    });
    if (isMobile && user?._id) {
      return navigate(`/admin/planning/${user?._id}`);
    }
    return null;
  }

  function handleNote() {
    const payload = note || {
      user,
      startDate: filters.startDate,
      endDate: filters.endDate,
      text: '',
    };
    dispatch({
      type: GET_NOTE,
      payload,
    });
  }

  function handleDateUpdate() {
    setForcastIsOpen(!forcastIsOpen);
    if (forcastShifts.length === 0) {
      setForcastIsOpen(true);
      saveCurrentDayShiftAction(dispatch, {
        date: filters.startDate,
        structure: filters.structure,
        user: user?._id,
        emptyForcast: !(shifts.length > 0),
      });
    }
  }

  async function deleteDaysShift() {
    await deleteDayShiftAction(dispatch, {
      date: filters.startDate,
      structure: filters.structure,
      user: user?._id,
      line: line?._id,
    });
    confirmModalRef?.current?.close();
  }

  return (
    <>
      <div className={`${styles.container} ${note ? styles.isNote : ''}`}>
        {isValid && user
          && <div className={styles.valid}>
            <BsCheck />
          </div>
        }
        {user
          && <div className={styles.user}>
            <div className={styles.picture}>
              {user?.picture?.path
                ? <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture.path}`} alt={user.picture.alt} />
                : <p>
                  {user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}
                </p>
              }
            </div>
            <p className={styles.name}>
              {user?.profile?.firstName && user?.profile?.firstName[0]}. {user.profile.lastName}
            </p>
            {filters.endDate && filters.startDate
              && <button
                className={styles.note}
                onClick={() => handleNote()}
              >
                {note?._id ? <RiFileFill size={16} /> : <IoMdAdd />}
              </button>
            }
            {hours > 0 && <p className={styles.hours}>
              {Math.floor(hours)}H{minutes > 0 && minutes.toFixed()}
            </p>}
          </div>
        }
        {line
          && <div className={styles.line}>
            <p className={styles.name}>{name}</p>
            <p>{line.name}</p>
          </div>
        }
        <div className={styles.content}>
          {week.length === 0
            ? <div
              className={styles.timeline}>
              <TimeLine
                shifts={shifts.filter((s: IShift) => s.isActive)}
                absencesOptions={absencesOptions}
                openModale={() => {
                  if (!isValid) {
                    modalRef.current.open();
                  } else if (userAuth?.role === 'Admin') {
                    modalRef.current.open();
                  }
                }}
              />
            </div>
            : <div
              className={styles.timeline}
            >
              <div className={styles.week}>
                {week.map((d: DayType) => <div
                  key={d.date}
                  className={styles.day}
                  onClick={() => handleSelectDate({
                    startDate: d.date,
                    endDate: null,
                  })}
                >
                  <TimeLine
                    shifts={shifts?.filter(
                      (s: IShift) => isSameDay(new Date(s.date), new Date(d.date))
                        && s.isActive,
                    )}
                    isWeek
                    absencesOptions={absencesOptions}
                  />
                </div>)}
              </div>
            </div>
          }
          {forcastIsOpen && week.length === 0 && forcastShifts?.length > 0
            && <div
              className={`${styles.timeline} ${styles.forcast}`}>
              <TimeLine shifts={forcastShifts} isForcast absencesOptions={absencesOptions} />
            </div>
          }
        </div>
        {user && dateUpdate && week.length === 0
          && <button
            className={styles.alert}
            onClick={() => handleDateUpdate()}
          >
            <div
              className={`${styles.icon} ${styles.danger}`}
            >
              <AiTwotoneWarning />
            </div>
            <p>{dateUpdate.message}</p>
          </button>
        }
        <div className={styles.actions}>
          {week.length === 0 && shifts.length > 0 && !isValid && (userAuth?.role === 'Admin' || userAuth?.role === 'Manager')
            && <button
              onClick={() => confirmModalRef?.current?.open()}
            >
              <div
                className={`${styles.icon} ${styles.danger}`}
              >
                <IoTrash />
              </div>
            </button>
          }
          {week.length === 0 && user && !isValid && (userAuth?.role === 'Admin' || userAuth?.role === 'Manager')
            && <button
              onClick={() => modalImportRef.current.open()}
            >
              <div
                className={`${styles.icon} ${styles.import}`}
              >
                <BsPersonFillDown />
              </div>
            </button>
          }
        </div>
      </div>
      <ModalStandalone ref={confirmModalRef} minWidth={450} isClose={false}>
        <ConfirmModal
          message={'Êtes-vous sûr de vouloir supprimer cette ligne ?'}
          confirmAction={() => deleteDaysShift()}
          abort={() => confirmModalRef?.current?.close()}
        />
      </ModalStandalone>
      <ModalStandalone ref={modalRef} isDraggable={true}>
        <ShiftModal
          user={user || undefined}
          line={line || undefined}
          pool={pool || undefined}
          lines={lines || undefined}
          shifts={shifts}
          close={() => modalRef.current.close()}
        />
      </ModalStandalone>
      {user
        && <ModalStandalone ref={modalImportRef}
          minWidth={500}
        >
          <ImportUserModal
            user={user || null}
            close={() => modalImportRef.current.close()}
          />
        </ModalStandalone>
      }
    </>
  );
}
