import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { AUTH_LOGOUT } from '../../actions/types';
import InputMedia from '../../lib/HooksFormFields/InputMedia';
import styles from './User.module.scss';
import { IPole, IStructure } from '../../types/structure';
import { IContract } from '../../types/contract';
import { getContractAction } from '../../actions/contract';

const ContractRow = ({ contract }: { contract: IContract }) => {
  const startDate = contract?.startDate && new Date(contract?.startDate);
  const endDate = contract?.endDate && new Date(contract?.endDate);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getContract = async () => {
    await getContractAction(dispatch, contract._id);
    return navigate(`/admin/user/contract/${contract.user}`);
  };
  return (
    <div className={`${styles.contract}`}
      onClick={() => getContract()}
    >
      <div className={styles['contract-col']}>
        {contract?.type}
      </div>
      <div className={styles['contract-col']}>
        {contract?.startDate && <span>{format(new Date(contract?.startDate), 'dd/MM/yyyy')}</span>}
        {contract?.endDate && <span> - {format(new Date(contract?.endDate), 'dd/MM/yyyy')}</span>}
      </div>
      <div className={styles['contract-col']}>
        {contract?.isAnnualized ? 'Annualisé' : ''}
      </div>
      <div className={styles['contract-col']}>
        {(startDate < new Date() && !endDate) || (startDate < new Date() && endDate > new Date()) ? 'En cours' : ''}
      </div>
    </div>
  );
};

const UserInfos = ({ onSubmit, isProfile = false }: { onSubmit: any, isProfile?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { watch } = useFormContext<any>();
  const form = watch();
  const { structuresList } = useSelector((store: any) => store.structureReducer);

  const { userContracts } = useSelector((store: any) => store.contractsReducer);
  const structure = structuresList?.find((s: IStructure) => s._id === form?.structure);
  const poles = structure?.poles
    ?.filter((p: IPole) => form?.poles?.find((po: string) => po === p._id))
    ?.map((p: IPole) => ({ label: p.name, color: p.color, _id: p._id }));

  // function formatDate(date: string) {
  //   return new Intl.DateTimeFormat('fr', { dateStyle: 'full' }).format(new Date(date));
  // }

  return (
    <div className={styles.container}>
      <div className={styles.bg} />
      <div className={styles.content}>
        <div className={styles.avatar}>
          <InputMedia
            name="picture"
            media={form?.picture}
            handleChange={onSubmit}
            submit={onSubmit}
            disabled={!form._id}
          />
          <div className={styles.name}>
            <h1>{form?.profile?.firstName}
              {' '}
              {form?.profile?.lastName}</h1>
            {structure?.name
              && <p>{structure?.name}</p>}
            {poles?.length > 0
              && <div className={styles.poles}>
                {poles.map((p: any) => <p
                  key={p._id}
                  style={{ backgroundColor: p.color }}
                >
                  {p.label}
                </p>)}
              </div>
            }
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.infos}>
            <div className={styles.col}>
              <h2>Infos personnelles</h2>
              {form.code
                && <div className={styles.field}>
                  <p>Matricule</p>
                  <p>{form.code}</p>
                </div>
              }
              <div className={styles.field}>
                <p>Mail</p>
                <p>{form.email}</p>
              </div>
              <div className={styles.field}>
                <p>Téléphone</p>
                <p>{form.phone}</p>
              </div>
            </div>
            {(form.role !== 'Admin' || form.role !== 'Owner') && !isProfile
              && <>
                <div className={styles.col}>
                  <div className={styles.list}>
                    {userContracts.length > 0
                      && <div className={`${styles.contract} ${styles.labels}`}>
                        <div className={styles['contract-col']}>
                          Contrat
                        </div>
                        <div className={styles['contract-col']}>
                          Période
                        </div>
                        <div className={styles['contract-col']}>
                          Type
                        </div>
                        <div className={styles['contract-col']}>
                          Statut
                        </div>
                      </div>
                    }
                    {userContracts.sort(
                      (a: IContract, b: IContract) => (
                        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
                      ),
                    )
                      ?.map((c: IContract) => <ContractRow
                        key={c?._id}
                        contract={c}
                      />)}
                  </div>
                </div>
              </>
            }
          </div>
          {isProfile
            && <div className={styles.logout}>
              <button
                type="button"
                onClick={() => {
                  navigate('/');
                  dispatch({
                    type: AUTH_LOGOUT,
                  });
                }}
              >
                <FiLogOut />
                ME DECONNECTER
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default UserInfos;
