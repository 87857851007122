import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uniqid from 'uniqid';
import { useDispatch, useSelector } from 'react-redux';
import { IShift } from '../../types/shift';
import styles from './TimeLine.module.scss';
import { getDurationLabel, hexToRgba } from '../../utils';
import { POST_SHIFT_ALERT, POST_SHIFT_ERROR, SET_SHIFT_MODALE } from '../../actions/types';

type IStep = {
  id: string,
  color: string,
  className: string,
  width: number,
  left: number,
  duration?: number,
  label?: string,
  value?: string,
  start?: string,
  end?: string,
  shift?: IShift | any,
};

const Step = ({
  step,
  isWeek = false,
  isHome = false,
  isForcast = true,
  openModale,
}: {
  step: IStep
  isWeek: boolean,
  isHome?: boolean,
  isForcast?: boolean,
  openModale?: () => void | undefined,
}) => {
  const { user } = useSelector((d: any) => d.authReducer);
  const dispatch = useDispatch();

  function setActiveShiftModale(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (user.role === 'Member' || user.role === 'Owner') {
      return null;
    }
    if (step.shift && openModale) {
      openModale();
      dispatch({
        type: POST_SHIFT_ERROR,
        payload: null,
      });
      dispatch({
        type: POST_SHIFT_ALERT,
        payload: null,
      });
      dispatch({
        type: SET_SHIFT_MODALE,
        payload: step.shift,
      });
    }
    return null;
  }

  return (
    <div className={`${styles.step} ${isWeek ? styles.isWeek : ''}`} style={{ width: `${step.width}%` }}>
      <button
        type="button"
        onClick={(e) => setActiveShiftModale(e)}
        className={styles.content}
        style={{
          backgroundColor: step.color ? hexToRgba(step.color, isForcast ? 0.3 : 0.5) : 'red',
        }}
      >
        {(!isWeek && !isHome && step.value !== 'break')
          && <div className={`${styles.label} ${(step.width < 11 || isWeek || isHome) ? styles.hide : ''}`}>
            <p>{step?.label}</p>
            {step?.start && step?.end && <p>{step?.start} - {step?.end}</p>}
          </div>
        }
      </button>
      {step.duration
        && <div className={styles.tooltip}>
          <div className={styles.infos}>
            <div
              className={styles.puce}
              style={{
                backgroundColor: step.color ? hexToRgba(step?.color, 0.45) : 'red',
              }}
            />
            <p>{step?.label}</p>
          </div>
          <div className={styles.infos}>
            {step?.start && step?.end && <p>{step?.start} - {step?.end}</p>}
            {step?.duration && <p>{getDurationLabel(step?.duration)}</p>}
          </div>
        </div>
      }
    </div>
  );
};

export default function TimeLine({
  shifts = [],
  isWeek = false,
  isHome = false,
  isForcast = false,
  absencesOptions = [],
  openModale,
}: {
  shifts: IShift[]
  isWeek?: boolean,
  isHome?: boolean,
  isForcast?: boolean,
  absencesOptions?: string[],
  openModale?: () => void | undefined,
}) {
  const [steps, setSteps] = useState<IStep[]>([]);
  const { list } = useSelector((store: any) => store.shiftsReducer);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (list?.shifts?.length > 0) {
      const updatedSlices: IStep[] = [];
      const stepWidth = 100 / (list.shifts.length);
      let empties: string[] = [];
      let slicesFound: string[] = [];
      const left = 0;
      if (shifts?.length > 0) {
        list.shifts.forEach((t: any) => {
          const foundIndex = shifts.findIndex((s) => s.slices[0] === t.label);
          let slices: any = null;
          if (foundIndex !== -1) {
            const shift = shifts[foundIndex];
            const endShiftIndex = list?.shifts
              .findIndex((s: any) => s.label === shift?.slices[shift.slices.length - 1]);
            if (shift.slices.length > 0) {
              const label = list?.activitiesOptions?.find(
                (a: any) => a.value === shift.activity,
              )?.label || shift.activity;
              const value = list?.activitiesOptions?.find(
                (a: any) => a.value === shift.activity,
              )?.value || shift.activity;
              const color = shift.structure?.poles?.find(
                (p: any) => p._id === shift.pole,
              )?.activities?.find((a: any) => a.label === shift.activity)?.color
                || shift.structure?.poles?.find((p: any) => p._id === shift.pole)?.color
                || list?.activitiesOptions?.find((a: any) => a.value === shift.activity)?.color;
              slices = {
                id: uniqid(),
                label,
                value,
                start: shift.slices[0],
                end: list.shifts[endShiftIndex + 1]?.label,
                duration: (shift.slices.length * 5) / 60,
                color,
                className: 'step',
                width: shift.slices.length * stepWidth,
                left,
                shift,
              };
              slicesFound = [...slicesFound, ...shift.slices];
            }
            if (empties.length > 0) {
              updatedSlices.push({
                id: uniqid(),
                color: isHome ? '#CAD1DB' : '#EDF2F9',
                className: 'empty',
                left,
                width: empties.length * stepWidth,
                shift: {
                  slices: [empties[0]],
                  activity: 'rest',
                  duration: {
                    hours: 1,
                    minutes: 0,
                  },
                },
              });
              empties = [];
            }
            if (slices) {
              updatedSlices.push(slices);
            }
          } else if (!slicesFound.find((s) => s === t.label)) {
            empties.push(t.label);
            slicesFound.push(t.label);
          }
        });
        if (empties.length > 1) {
          updatedSlices.push({
            id: uniqid(),
            color: isHome ? '#CAD1DB' : '#EDF2F9',
            className: 'empty',
            left,
            width: empties.length * stepWidth,
            shift: {
              activity: 'rest',
              slices: [empties[0]],
              duration: {
                hours: 1,
                minutes: 0,
              },
            },
          });
        }
        let durationCount = 0;
        updatedSlices
          .filter((s) => s.label && s.duration
            && !absencesOptions.find((a: any) => a === s.value))
          .forEach((s: IStep) => {
            if (s.duration) {
              durationCount += s.duration;
            }
          });
        setDuration(durationCount);
        setSteps(updatedSlices);
      } else {
        const empty = {
          id: uniqid(),
          color: isHome ? '#FFFFFF' : '#EDF2F9',
          className: 'empty',
          width: 100,
          left,
          shift: {
            slices: [list.shifts[0].label],
          },
        };
        updatedSlices.push(empty);
        setSteps(updatedSlices);
        setDuration(0);
      }
    }
  }, [list?.shifts, shifts]);
  return (
    <div className={styles.container}>
      {isWeek && duration > 0 && <p className={styles.duration}>{getDurationLabel(duration)}</p>}
      {steps.map((s) => (
        <Step
          key={s.id}
          step={s}
          isWeek={isWeek}
          isForcast={isForcast}
          isHome={isHome}
          openModale={openModale}
        />
      ))}
    </div>
  );
}
