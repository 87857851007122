import { Dispatch } from 'redux';
import { getData, postData } from '.';
import {
  GET_USER_FORECASTS,
  USER_ERROR,
} from './types';

export const getUserForecastAction = async (dispatch: Dispatch, user: string, year: number) => {
  const url = `/forecast/${user}/${year}`;
  await dispatch({
    type: GET_USER_FORECASTS,
    payload: [],
  });
  const response = await getData(USER_ERROR, url, dispatch, true);
  if (response.data.forecasts) {
    dispatch({
      type: GET_USER_FORECASTS,
      payload: response.data.forecasts,
    });
  }
};

export const createUserForecastAction = async (dispatch :Dispatch, data: any) => {
  const url = '/forecast';
  const response = await postData(USER_ERROR, url, dispatch, data, true);
  if (response?.data.forecasts) {
    dispatch({
      type: GET_USER_FORECASTS,
      payload: response.data.forecasts,
    });
  }
  return response;
};
