import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';

import Layout from './components/Layout';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Loader from './components/Loader';
import Toaster from './lib/Toaster';
import AdminPlanning from './pages/AdminPlanning';
import UserPlanning from './pages/UserPlanning';
import Profile from './pages/Profile/Profile';
import StructuresList from './pages/StructuresList/StructuresList';
import Structure from './pages/Structure/Structure';
import UsersList from './pages/UsersList/UsersList';
import User from './pages/User/User';
import MemberHome from './pages/MemberHome/MemberHome';
import UserNotifs from './pages/UserNotifs/UserNotifs';
import Export from './pages/Export';
import Contracts from './pages/Contracts/Contracts';
import UserForecast from './pages/UserForecast';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getProfileAction(dispatch).catch(() => {
      navigate('/');
    });
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { authReducer } = useSelector((store: any) => store);
  const { user } = authReducer;
  const location = useLocation();
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {user?.role !== 'Member' && (
          <Route
            path="*"
            element={(
              <Layout key="app" role={user?.role}>
                <Routes>
                  <Route path="/admin/planning/:userId" element={<UserPlanning/>} />
                  <Route path="/admin/planning" element={<AdminPlanning />} />
                  <Route path="/admin/user" element={<UsersList />} />
                  <Route path="/admin/user/create" element={<User isCreate />} />
                  <Route path="/admin/user/forecast/:id" element={<UserForecast />} />
                  <Route path="/admin/user/:id" element={<User />} />
                  <Route path="/admin/user/contract/:id" element={<Contracts />} />
                  <Route path="/admin/site" element={<StructuresList />} />
                  <Route path="/admin/site/create" element={<Structure isCreate />} />
                  <Route path="/admin/site/:id" element={<Structure />} />
                  <Route path="/admin/profile" element={<Profile />} />
                  <Route path="/admin/notif" element={<UserNotifs />} />
                </Routes>
              </Layout>
            )}
          />
        )}
        {user?.role === 'Member' && (
          <Route
            path="*"
            element={(
              <Layout key="app" role={user?.role}>
                <Routes>
                  <Route path="/member/home" element={<MemberHome />} />
                  <Route path="/member/planning/:userId" element={<UserPlanning/>} />
                  <Route path="/member/profile" element={<Profile />} />
                  <Route path="/member/notif" element={<UserNotifs />} />
                </Routes>
              </Layout>
            )}
          />
        )}
        <Route path="/export-preview/:token" element={<Export isPreview />} />
        <Route path="/export/:token" element={<Export />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
      {!location.pathname.includes('/export') && <Toaster />}
    </>
  );
};

export default AppRoutes;
