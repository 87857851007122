import { Dispatch } from 'redux';
import {
  getData, putData, postData, deleteData,
} from '.';
import {
  CONTRACT_ERROR,
  CONTRACT_LOADING,
  DELETE_CONTRACT,
  GET_CONTRACT,
  GET_STRUCTURE_CONTRACTS,
  GET_USER_CONTRACTS,
  POST_CONTRACT,
  PUT_CONTRACT,
  SET_TOAST,
} from './types';

export const getUserContractsAction = async (dispatch: Dispatch, id: string) => {
  const url = `/contract/user/${id}`;
  dispatch({
    type: CONTRACT_LOADING,
    payload: GET_USER_CONTRACTS,
  });
  const response = await getData(CONTRACT_ERROR, url, dispatch, true);
  if (response.data?.contracts) {
    dispatch({
      type: GET_USER_CONTRACTS,
      payload: response.data.contracts,
    });
  }
};

export const postContractsFilteredAction = async (dispatch :Dispatch, data: any) => {
  const url = '/contract/filtered';

  const response = await postData(CONTRACT_ERROR, url, dispatch, data, true);

  if (response?.data?.contracts) {
    dispatch({
      type: GET_STRUCTURE_CONTRACTS,
      payload: response.data?.contracts,
    });
  }
  return response;
};

export const getContractAction = async (dispatch: Dispatch, id: string) => {
  const url = `/contract/${id}`;
  dispatch({
    type: CONTRACT_LOADING,
    payload: GET_CONTRACT,
  });
  const response = await getData(CONTRACT_ERROR, url, dispatch, true);
  if (response.data.contract) {
    dispatch({
      type: GET_CONTRACT,
      payload: response.data.contract,
    });
  }
};

export const updateContractAction = async (dispatch :Dispatch, id: string, data: any) => {
  const url = `/contract/${id}`;
  dispatch({
    type: CONTRACT_LOADING,

  });
  const response = await putData(CONTRACT_ERROR, url, dispatch, data, true);

  if (response.data?.contract) {
    dispatch({
      type: PUT_CONTRACT,
      payload: response.data?.contract,
    });
    if (response.data?.error === 'A slot already exists for this contract') {
      dispatch({
        type: SET_TOAST,
        payload: {
          type: 'alert',
          message: 'Des créneaux ont été supprimés',
        },
      });
    } else {
      dispatch({
        type: SET_TOAST,
        payload: {
          message: 'Le contrat a été modifié',
          type: 'success',
        },
      });
    }
  }
  return response;
};

export const createContractAction = async (dispatch :Dispatch, data: any) => {
  const url = '/contract';
  dispatch({
    type: CONTRACT_LOADING,
  });
  const response = await postData(CONTRACT_ERROR, url, dispatch, data, true);

  if (response?.data?.contract) {
    dispatch({
      type: POST_CONTRACT,
      payload: response.data?.contract,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le contrat a été créé',
        type: 'success',
      },
    });
  }
  return response;
};

export const deleteContractAction = async (dispatch: Dispatch, id: string) => {
  const url = `/contract/${id}`;
  const response: any = await deleteData(CONTRACT_ERROR, url, dispatch, true);
  if (response?.data) {
    dispatch({
      type: DELETE_CONTRACT,
      payload: id,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'Le contrat a été supprimé',
        type: 'success',
      },
    });
  }
  return response;
};
