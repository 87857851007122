import React from 'react';
import styles from './ConfirmModal.module.scss';

export default function ConfirmModal({
  message,
  confirmAction,
  abort,
}:{
  message: string,
  confirmAction: () => void,
  abort: () => void,
}) {
  return (
    <div className={styles.confirm}>
      <p>{message}</p>
      <div className={styles.actions}>
        <button
          type='button'
          onClick={() => abort()}
        >
          <span>Annuler</span>
        </button>
        <button
          type='button'
          onClick={() => confirmAction()}
        >
          <span>Confirmer</span>
        </button>
      </div>
    </div>
  );
}
